import React, { useEffect, useMemo, useRef, useState } from "react";
import { MapContainer, TileLayer, Polygon, Marker } from "react-leaflet";
import { polygonToCells, cellToBoundary } from "h3-js";
import "leaflet/dist/leaflet.css";
import NetworkService from "./NetworkService";
import colors from "./colors.json";
import { polygon } from "./belgradePolygon";
import "leaflet-rotatedmarker";
import "./App.css";

const RIDE_REQUEST_SCALE = 2;

const App = () => {
  const center = [44.80317492488992, 20.45098200650307];
  const resolution = 8;

  const [rotation, setRotation] = useState(0);
  const [driverLat, setDriverLat] = useState(0);
  const [driverLng, setDriverLng] = useState(0);

  useEffect(() => {
    const updateDriverData = () => {
      const params = new URLSearchParams(window.driverData);
      setDriverLat(params.get("driverLat") || 0);
      setDriverLng(params.get("driverLng") || 0);
      setRotation(params.get("rotation") || 0);
    };
    updateDriverData();
    const intervalId = setInterval(updateDriverData, 5000);
    return () => clearInterval(intervalId);
  }, []);

  const carIcon = window?.L?.icon({
    iconUrl: "/cargo-car-pin.svg",
    className: "carIcon",
    iconSize: [32, 32],
    className: "carIcon",
  });

  const [mapData, setMapData] = useState(null);

  useEffect(() => {
    const fetchZonesData = () => {
      NetworkService.getZonesData()
        .then((zones) => {
          setMapData(zones);
        })
        .catch((error) => {
          console.error("Error fetching zones data:", error);
        });
    };
    fetchZonesData();
    const intervalId = setInterval(fetchZonesData, 30000);
    return () => clearInterval(intervalId);
  }, []);

  const hexagonToPolygon = (hex) => {
    const boundary = cellToBoundary(hex, true);
    return boundary.map(([lat, lng]) => [lng, lat]);
  };

  const polygons = useMemo(() => {
    if (!mapData) {
      return [];
    }
    const hexagons = polygonToCells([polygon], resolution);
    return hexagons.map((hex) => {
      const hexagonData = mapData.find((h) => h.h3_hash === hex);
      if (!hexagonData) {
        return null;
      }
      const requests = Math.round(hexagonData?.request_count / RIDE_REQUEST_SCALE);
      const color = colors[Math.min(requests, 20)] || "transparent";
      return <Polygon key={hex} positions={hexagonToPolygon(hex)} fillColor={color} weight={0} fillOpacity={0.5} />;
    });
  }, [mapData, resolution]);

  const markerRef = useRef();
  const marker = useMemo(() => {
    return (
      <Marker
        ref={markerRef}
        // rotationAngle={rotation}
        rotationOrigin="center center"
        icon={carIcon}
        position={[driverLat, driverLng]}
      />
    );
  }, [driverLat, driverLng, rotation]);

  useEffect(() => {
    if (markerRef?.current) {
      markerRef.current.className = "carIcon";
      markerRef.current.setRotationAngle(rotation);
    }
  }, [rotation, markerRef]);

  return (
    <MapContainer
      attributionControl={false}
      zoomControl={false}
      center={center}
      zoom={12}
      style={{ height: "100vh", width: "100%" }}
      maxZoom={14}
      minZoom={11}>
      <TileLayer attribution="CAR:GO" url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png" />
      {marker}
      {polygons}
    </MapContainer>
  );
};

export default App;
